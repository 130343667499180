@media (max-width: 780px) {
  .user-dropdown {
    display: none;
  }

  .mobile-user-name {
    display: block;
  }

  .desktop-user {
    display: none;
  }

  .user-menu-padding {
    padding-top: 10px;
  }
}

@media (min-width: 781px) {
  .user-dropdown {
    display: block;
  }

  .mobile-user-name {
    display: none;
  }

  .desktop-user {
    display: block;
  }
}

@media (max-width: 450px) {
  #topbar {
    height: 42x !important;

    .contact-info {
      justify-content: center;
    }
  }

  .carouselCard {
    left: 13% !important;
    width: 75%;
    text-align: center;
  }
}

@media (max-width: 950px) {
  .total-quantity-small {
    display: block;
  }

  .total-quantity-large {
    display: none;
  }
}

@media (min-width: 950px) {
  .total-quantity-small {
    display: none;
  }

  // .total-quantity-large {
  //   display: block;
  // }
}

@media (max-width: 900px) {
  .topChangeProject div .form-group {
    left: 18%;
  }

  #topbar .sp-project {
    display: none;
  }
}

@media (max-width: 650px) {
  #topbar {
    height: auto;
  }

  #topbar .sp-project {
    display: none;
  }

  .topChangeProject div {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

//responsive

@media (max-width: 900px) {
  .search-project-card {
    display: none;
  }

  .search-project-card-responsive {
    display: block;
  }
}

@media (min-width: 900px) {
  .search-project-card {
    display: block;
  }

  .search-project-card-responsive {
    display: none;
  }
}
