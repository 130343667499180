#MyProfile {
	background: #fff;
}
.Myprofile_add {
	margin-bottom: 20px;
}
.Myprofile_editer {
	position: absolute;
	top: 0px;
	right: 0px;
}

.Recevied_trees_date {
	list-style: none;
	padding: 0px;
	margin: 0px;
	li {
		padding: 10px;
		&:first-child {
			width: 120px;
		}
		img {
			width: 100%;
		}
	}
	h5 {
		margin: 0px;
		font-weight: 600;
	}
}
.referred_Card {
	text-align: center;
	border: none;
	background: transparent;
	img {
		margin-bottom: 10px;
	}
	p {
		line-height: 30px;
	}
}
.Btn_green {
width: -webkit-fill-available;
}