.loader {
  position: absolute;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1100;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  span {
    margin: auto;
    margin-top: 25% !important;
  }
}

#root {
  position: relative;
}
