@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
.ag-theme-alpine {
  @include ag-theme-alpine(
    (
           // use
      alpine-active-color: deeppink
    )
  );

  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support
    background: #694d35 !important;
    color: #fff;

    // height: 40px !important;
    // min-height: 40px !important;
  }
  .ag-root-wrapper {
    border-radius: 10px;
  }

  .ag-header-cell-label {
    color: #fff;
    font-family: "Gotham Narrow Book", sans-serif !important;
    font-size: 12px;
    letter-spacing: 1px;
  }

  // .ag-center-cols-container{
  //      position: unset;
  // }

  .ag-center-cols-viewport {
    height: calc(100% + 15px);
  }
}
.grid {
  .ag-center-cols-container {
    position: unset;
  }
}

th.theme-table-header {
  background-color: #bb8b63 !important;
  color: #fff !important;
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-print .ag-center-cols-container {
  min-height: 40px !important;
}

// .ag-theme-alpine .ag-ltr .ag-cell {
//   text-transform: capitalize !important;
// }

.ag-theme-alpine2 {
  @include ag-theme-alpine(
    (
           // use
      alpine-active-color: deeppink,
      header-column-separator: true,
      borders: false,
           // header-column-separator-height:
           // header-column-separator-width:
           // header-column-separator-color: ag-derived(border-color, $opacity:
    )
  );

  .ag-header2 {
    // or write CSS selectors to make customisations beyond what the parameters support
    background: #00000015 !important;
    color: #fff;
    margin-bottom: 12px;
    display: none;

    // height: 40px !important;
    // min-height: 40px !important;
  }
  .ag-root-wrapper {
    border: none;
    // box-shadow: 10px;
    // box-sizing: border-box;
  }

  // .ag-body-container{
  //   colo
  // }

  .ag-header-cell-label2 {
    color: #fff;
    font-family: "Gotham Narrow Book", sans-serif !important;
    font-size: 12px;
    letter-spacing: 1px;
  }

  // .ag-center-cols-container{
  //      position: unset;
  // }

  .ag-center-cols-viewport2 {
    height: calc(100% + 15px);
  }
}

// .rowmargin
