.me-auto {
  margin-right: auto !important;
}
.text {
  &-right {
    text-align: right !important;
  }
}

.m {
  &l {
    &-2 {
      margin-left: 0.5rem !important;
    }
  }
  &r {
    &-2 {
      margin-right: 0.5rem !important;
    }
  }
}

.c-hove {
  &:hover {
    cursor: pointer;
  }
}

.link-user-options {
  padding: 5px !important;
  color: #000 !important;
  &:hover {
    text-decoration: none;
  }
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.addPopupButton {
  min-width: 95px;
}

.align_count {
  min-width: 20px;
}
.selectRequired {
  border-left: 3px solid #bb8b63 !important;
  border-radius: 5px;
}
.react-datepicker-wrapper {
  width: -webkit-fill-available;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
  color: indianred !important;
}
//firefox action Button issue fix

.Action_btn {
  line-height: normal !important;
}

.react-datepicker__day--outside-month {
  color: transparent;
  pointer-events: none;
  visibility: hidden;
}

.form-group {
  .react-datepicker-wrapper {
    display: block !important;
  }
}
