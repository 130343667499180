.userCard {
  .card {
    box-shadow: 0 0 1px #00000020, 0 1px 3px #00000033;
    // margin-bottom: 1rem;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    &-header {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border-bottom: none !important;
      height: auto !important;
      padding: 1rem;
      text-align: center;

      background: -webkit-linear-gradient(180deg, #88888a 0%, #6b6a72 100%);
      background: -moz-linear-gradient(180deg, #88888a 0%, #6b6a72 100%);
      background: -o-linear-gradient(180deg, #88888a 0%, #6b6a72 100%);
      background: linear-gradient(180deg, #88888a 0%, #6b6a72 100%);
      color: #f9f9f9;
      position: relative;

      .editIcon {
        position: absolute;
        top: 0;
        padding: 6px;
        right: 0;
        cursor: pointer;
      }

      &-name {
        font-size: 17px;
        font-weight: 600 !important;
        // left:50%;
        margin-left: 27%;
        margin-top: 10%;
        overflow-wrap: break-word;
        // overflow: hidden;
        width: 75%;
        // height: 20px;
      }
      &-name2 {
        font-size: 17px;
        font-weight: 600 !important;
        // left:50%;
        // margin-left: 10%;
        margin-top: 10%;
        overflow-wrap: break-word;
        // overflow: hidden;
        width: 75%;
        // height: 20px;
      }
      &-desc {
        font-size: 20px;
        display: inline-block;
        margin-bottom: 0.5rem;
        font-family: inherit;
        font-weight: 400;
        line-height: 1.2;
        color: inherit;
        margin-left: 29%;
      }
      &-desc2 {
        font-size: 20px;
        display: inline-block;
        margin-bottom: 0.5rem;
        font-family: inherit;
        font-weight: 400;
        line-height: 1.2;
        color: inherit;
        // margin-left: 15%;
      }
    }
    &-headers {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border-bottom: none !important;
      // height: 135px;
      padding: 1rem;
      text-align: center;

      background: -webkit-linear-gradient(180deg, #b8b6b7 0%, #a6a1a2 100%);
      background: -moz-linear-gradient(180deg, #b8b6b7 0%, #a6a1a2 100%);
      background: -o-linear-gradient(180deg, #b8b6b7 0%, #a6a1a2 100%);
      background: linear-gradient(180deg, #b8b6b7 0%, #a6a1a2 100%);
      color: #694d35;
      position: relative;

      .editIcon {
        position: absolute;
        top: 0;
        padding: 6px;
        right: 0;
        cursor: pointer;
      }

      &-name {
        font-size: 17px;
        font-weight: 600 !important;
        // left:50%;
        margin-left: 27%;
        margin-top: 10%;
        overflow-wrap: break-word;
        // overflow: hidden;
        width: 75%;
        // height: 20px;
      }
      &-desc {
        font-size: 20px;
        display: inline-block;
        margin-bottom: 0.5rem;
        font-family: inherit;
        font-weight: 400;
        line-height: 1.2;
        color: inherit;
        margin-left: 25%;
      }
    }
    &-user-img {
      left: 0;
      // margin-left: -45px;
      position: absolute;
      padding: 3%;
      margin-top: 5%;
      // top: 85px;
      img {
        border: 3px solid #fff;
        height: 85px;
        width: 85px;
        border-radius: 50%;
        box-shadow: 0 3px 6px #c4c3c329, 0 3px 6px #f5f5f53b !important;
      }
    }
    &-footer {
      // padding: 50px 1.25rem 0.75rem 1.25rem !important;
      border-top: none !important;
      // background-color: #caf0fa;
      background: -webkit-linear-gradient(180deg, #b8b6b7 0%, #d1c8bc 100%);
      background: -moz-linear-gradient(180deg, #d1c8bc 0%, #d1c8bc 100%);
      background: -o-linear-gradient(180deg, #d1c8bc 0%, #d1c8bc 100%);
      background: linear-gradient(180deg, #d1c8bc 0%, #d1c8bc 100%);
      &-heading {
        color: rgb(121, 118, 118);
        font-weight: 600;
        padding: none;
        margin: 0px !important;
      }
    }
  }
}
.planned-card,
.planted-card,
.dead-card {
  height: 70px;
  width: 50%;
  background-color: #e3f9ff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 12px 12px 12px;
  // margin-top: 10px;
  margin: 0 6px 0 0;


  .planned-text {
    font: normal normal normal 14px/16px Open Sans;
    font-family: "OpenSans-Regular";
    letter-spacing: 0.42px;
    color: #6c6868;
  }

  .planned-count {
    font: normal normal 600 24px/28px Work Sans;
    font-family: "OpenSans-SemiBold";
    letter-spacing: 0px;
    color: #191616;
  }
}

.planted-card {
  background-color: #d3ffd5;
  flex-direction: column;
  align-items: flex-start;
  // padding: 12px !important;
  margin: 0 6px 0 0;
}

.dead-card {
  background-color: #ffd3d3;
  flex-direction: column;
  align-items: flex-start;
  // padding: 12px !important;
  margin: 0 6px 0 0;
}

.pindate {
  // top: 623px;
  // left: 96px;
  // width: 341px;
  // height: 25px;
  text-align: left;
  font: normal normal 900 13px/18px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  // padding-bottom: 5%;
}

@media only screen and (max-width: 760px) {
  .topPerformers-card {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
  }

  .landingPage {
    .col {
      display: flex;
      justify-content: center;
    }
  }
}
.topPerformers {
  h6 {
    letter-spacing: 1px;
    font-weight: normal !important;
  }

  &-card {
    box-shadow: 0 0 1px #00000020, 0 1px 3px #00000033;
    max-width: 250px;
    margin: 10px;
    width: 250px;
    min-width: 250px;
    padding: 0 !important;
    height: 100px;
    overflow: auto;
    border-radius: 5px;
    background-color: #d7fae2;
    &-img {
      // height: 100px;
      // width: 120px;
      // min-width: 120px;
      // min-height: 100px;
      border: 2px solid #fff;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
      margin-left: 4%;
      margin-top: 4%;
    }
    &-name {
      font: normal normal normal 16px/19px Work Sans;
      font-family: "OpenSans-Regular";
      letter-spacing: 0.48px;
      color: #694d35;
      padding-bottom: 2px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-sub {
      font: normal normal normal 11px/13px Work Sans;
      font-family: "OpenSans-Regular";
      letter-spacing: 0.33px;
      color: #000;
      opacity: 0.48;
    }
  }
}

.recentPlantings {
  h6 {
    letter-spacing: 1px;
    font-weight: normal !important;
  }
  .create-link {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .plantView:hover {
    transform: scale(1.05);
    transition: all 500ms;
    cursor: pointer;
  }
  .plantView {
    width: 150px;
    height: 100px;
    position: relative;
    border-bottom: none !important;
    margin-top: 10px;
    box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
    &-footer {
      border-top: none !important;
      background-color: #f4fae0;
      width: 150px;
      box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
      height: 100px;
      // border-radius: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      z-index: 0;
      border-bottom: none !important;
    }
  }
  .overlay {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 100px;
    border-radius: 5px;
    background-color: #00000048;
    color: #fff;

    p {
      font-size: 14px;
      letter-spacing: 1px;
      margin: 0 !important;
    }
    h6 {
      font-size: 16px !important;
      margin: 0 !important;
    }
  }
}

.recentFooter {
  // padding: 50px 1.25rem 0.75rem 1.25rem !important;
  border-top: none !important;
  background-color: #fafafa;
  width: 150px;
  box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;

  // border-width: 1px;
  // border-style: solid;
  // border-color: #000;
}

.achievement {
  .card {
    box-shadow: 0 0 1px #00000020, 0 1px 3px #00000033;
    max-width: 200px !important;
    width: 200px;
    height: 80px;
    margin: 5px;
  }
  &-details {
    width: 100%;
    height: 100%;
    margin-left: 10px;
    justify-content: flex-end;
    p {
      margin: 0px !important;
    }
  }
  &-icon {
    background-color: #bb8b63;
    width: 100px;
    height: 79px;
    display: flex;
    i {
      margin: auto;
      font-size: 30px;
    }
  }
  &-heading {
    font-weight: 600;
    padding: none;
    margin: 0px !important;
    letter-spacing: 2px;
    word-spacing: 2px;
  }
}

.flexbox-container {
  display: flex;
  flex-direction: row;
}
