#Sign-upbox {
  background: -webkit-linear-gradient(180deg, #ffe3cd 0%, #ffb8ab 100%);
  background: -moz-linear-gradient(180deg, #ffe3cd 0%, #ffb8ab 100%);
  background: -o-linear-gradient(180deg, #ffe3cd 0%, #ffb8ab 100%);
  background: linear-gradient(180deg, #ffe3cd 0%, #ffb8ab 100%);
  min-height: calc(100vh - 0px);
  overflow: auto;
  .card {
    font-family: "Gotham Narrow Book", sans-serif;

    width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 3em;
    border-radius: 10px;
    background-color: #ffff;
    padding: 1.8rem;
    // box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.1);
  }
  label {
    font-weight: 600;
    font-size: 14px;
  }
}
.Btn_green {
  background-color: #bb8b63;
  color: #fff;
  padding: 12px 30px;
  &:hover {
    color: #ffffff;
    background-color: #8f6a4b;
  }
}
.Sign-up_FormBox {
  // width: 70%;
  // position: relative;
  // margin-left: 25%;
  // margin-right: 0;
}
@media (max-width: 900px) {
  .Sign-up_FormBox {
    // margin-left: 5%;
  }
}
.Sign-up_ImgBox {
  // position: absolute;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  right: 78%;
  top: 10%;
  text-align: center;
  padding: 0px;
  background-color: white;
}
.Sign-up_ImgBox_inner {
  border: 1px solid #dedede;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  position: relative;
  img {
    // width: 90%;
    width: 175px;
    border-radius: 50%;
    height: 175px;
  }
  input[type="file"] {
    width: 175px;
    height: 175px;
    /* height: 155px; */
    position: absolute;
    top: 0;
    left: 0px;
    /* width: 155px; */
    opacity: 0;
  }
}
@media (max-width: 768px) {
  #Sign-upbox {
    padding: 20px;
    .card {
      width: 700px;
    }
  }
}

.rigthsidecontainer {
  width: 100%;
  // padding-left: 190px;
  padding-left: 25%;
  padding-top: 15%;
  padding-bottom: 10%;
}

@media (min-width: 900px) {
  .rigthsidecontainer {
    right: 0;
  }
}
@media (max-width: 426px) {
  #Login {
    padding: 10px;
  }
  #Sign-upbox {
    .card {
      width: 100%;
      margin-top: 0em;
      margin-bottom: 3em;
      padding: 1rem;
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
  .Sign-up_ImgBox {
    position: relative;
    width: 175px;
    height: auto;
    text-align: center;
    padding: 0px;
    right: auto;
    margin: auto;
  }
  .Sign-up_ImgBox_inner {
    background-color: #fff;
  }
  .Sign-up_FormBox {
    width: 100%;
  }
}
.text-end {
  text-align: right !important;
}

/* Split the screen in half */
.split {
  height: 85.7%;
  width: 71.5%;
  position: fixed;
  z-index: 1;
  top: 10;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the left side */
.left {
  background-color: #111;
  background-image: url("/assets/img/Group 6858@2x.png");
  background-size: contain;
}

.signupleftimage {
  background-image: url("/assets/img/Group 6858@2x.png");
  opacity: 1;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  flex: 1;
}

@media (min-width: 900px) {
  .split {
    height: 85.7%;
    width: 71.5%;
  }
}

// .leftimage{
//   background-image: url("/assets/img/Group 6858@2x.png");
//   background-size:contain;
//   width:419px;
//   height:100%;
//   top:0;
// }

/* Control the right side */
.right {
  right: 0;
  background-color: #f6f4f3;
}
.input_field {
  border: 0;
  // padding: 0
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 14px;
  line-height: 30px;
  &:focus,
  &:valid {
    outline: 0;
    border-bottom-color: #665856;
  }
}

.center {
  margin: auto;
  width: 75%;
  // border: 3px solid green;
  padding: 10px;
  position: absolute;
  //   width:300px;
  //   height: 300px;
  //   background:#063;
  bottom: 0px;
  right: 25%;
  left: 50%;
  margin-left: -150px;
}

.footer-content {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  //   background-color: red;
  //   color: white;
  text-align: center;
}

.footer-content2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  //   background-color: red;
  //   color: white;
  text-align: center;
}

#headerNew {
  height: 20px;
  background: #fff;
  z-index: 997;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06);
  // border-bottom: 3px solid #bb8b63;
  .logo {
    h1 {
      font-size: 28px;
      margin: 0;
      padding: 0 0;
      line-height: 1;
      font-weight: 400;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    img {
      padding: 0;
      margin: 0;
      max-height: 40px;
    }
    .a,
    .a:hover {
      color: #bb8b63;
      text-decoration: none;
    }
  }
}

.header-items {
  position: fixed;
  top: 0px;
  right: 0px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-top: 0;
}

.button {
  background-color: #694d35;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 4px 4px;
  border-radius: 8px;
}

// .left-side {
//   background-image: url("/assets/img/tree_count.jpg");
//   background-size: cover;
//   background-repeat: no-repeat;
//   padding: 120px 0;
//   height: 475px
// }
.text-style-heading {
  top: 288px;
  left: 53px;
  width: 285px;
  // height: 96px;
  text-align: left;
  font: normal normal 900 70px/60px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.text-style-secondheading {
  left: 53px;
  // width: 320px;
  // height: 56px;
  top: 384px;
  text-align: start;
  font: normal normal 900 22px/35px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.leftBtn {
  background-color: #ffffff; /* Green */
  border: none;
  color: #694d35;
  padding: 12px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
}

.formFieldInput {
  width: 100%;
  background-color: transparent;
  border: 0;
  color: white;
  outline: none;
  border-bottom: 1px solid #445366;
  font-size: 1em;
  font-weight: 300;
  padding-bottom: 10px;
  margin-top: 10px;
}

.text-left {
  top: 488px;
  // left: 53px;
  // width: 340px;
  height: 126px;
  text-align: left;
  font: normal normal medium 16px/26px "Gotham Narrow Book", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.register {
  top: 104px;
  left: 536px;
  width: 125px;
  height: 36px;
  text-align: justify;
  font: normal normal normal 18px/22px "Gotham Narrow Book", sans-serif;
  letter-spacing: 1.04px;
  color: #694d35;
  text-transform: uppercase;
  opacity: 1;
}

.textContainer {
  // width: 320px;
  // height: 120px;
}
