#MyProfile {
  background: #fff;
}
.Myprofile_add {
  margin-bottom: 20px;
}
.Myprofile_add_inner {
  width: 150px;
  height: 150px;
  background: #bb8b63;
  margin: auto;
  border-radius: 50%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.Myprofile_editer {
  position: absolute;
  top: 0px;
  right: 0px;
  input[type="file"] {
    // height: 175px;
    position: absolute;
    left: 0px;
    // width: 175px;
    opacity: 0;
  }
}
.My_Pro_icon {
  width: 150px;
  height: 150px;
}
.Recevied_trees_date {
  list-style: none;
  padding: 0px;
  margin: 0px;
  li {
    padding: 10px;
    &:first-child {
      width: 120px;
    }
    img {
      width: 100%;
    }
  }
  h5 {
    margin: 0px;
    font-weight: 600;
  }
}
.referred_Card {
  text-align: center;
  border: none;
  background: transparent;
  img {
    margin-bottom: 10px;
  }
  p {
    line-height: 30px;
  }
}
