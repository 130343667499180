.visitorList{
  min-height: 500px;
  .card-heading{
    font-weight: 700;
    letter-spacing: .5px;
  }
  .card-heading-sub{
    font-weight: 400;
    letter-spacing: .5px;
    font-size: 12px;
  }
}