.wrapper {
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  /* margin: auto; */
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-bottom: -1rem;
}
.switch_box {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* max-width: 200px;
  min-width: 200px;
  height: 200px; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.box_1 {
  /* background: #eee; */
}

input[type="checkbox"].switch_1 {
  font-size: 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 2.2em;
  height: 1.2em;
  background: #ccc;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #419ca0;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1.3em);
}
