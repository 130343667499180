body {
  margin: 0 !important;
  padding: 0 !important;
}

// @media (min-width: 1200px)
// {
//   .container, .container-lg, .container-md, .container-sm, .container-xl {
//     max-width: 1320px !important;
// }
// }
// .modal_content {
//   background-color: white;
//   position: absolute;
// }
// .modal {
//   display: none;
//   position: fixed;
//   z-index: 1;
//   // background-color: rgba(0, 0, 0, 0.25);
// }
.form-label {
  color: #7e91a4;
  font-size: 14px !important ;
}
.form-control {
  &::placeholder {
    font-size: 14px !important ;
  }
}
.Select__placeholder {
  font-size: 14px !important ;
}
.required {
  border-left: 3px solid #bb8b63 !important;
  // border: 1px solid #999999;
}
.specialcharacter-required {
  color: red;
  font-size: larger;
  margin-top: -4px;
}
.Toastify__toast-body {
  font-size: 14px !important ;
}
.customAgTextSapling {
  color: #bb8b63;
  font-family: "Gotham Narrow Book", sans-serif !important;
  font-size: 17px !important;
  letter-spacing: 1px;
}

.customAgSubmitSapling {
  background-color: #bb8b63 !important;
}

.customAgSubmitSapling::hover {
  background-color: #bb8b63 !important;
}

.navbar ul li .active {
  font-weight: bolder;
}

.searchTable {
  width: auto;
  position: absolute;
  top: 10px;
  right: 0;
  text-align: end;
  right: 0;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

.dashboardAgGrid .ag-theme-alpine .ag-row {
  font-size: 12px;
}

.marginLeft7prtg ~ i {
  margin-left: 7% !important;
}

.form-group {
  position: relative;
}
.changePasswordEye {
  // float: right;
  // margin-top: -6% !important;
  // margin-right: 2% !important;
  position: absolute;
  right: 2%;
  top: 50%;
  cursor: pointer !important;
}

.loginPasswordEye {
  position: absolute;
  right: 12px;
  top: 35%;
}

.pl-3pg {
  padding-left: 3%;
}
.cursor-pointer {
  cursor: pointer;
}

.float-right {
  float: right !important;
}

.pt-2rem {
  padding-top: 2rem !important;
}

.select option {
  text-transform: capitalize;
}

.bgVruksha {
  background-color: #bb8b63 !important;
}
.colorVruksha {
  background: #34AF00 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  font: normal normal medium 17px/26px" Gotham Narrow" !important;
  letter-spacing: 0px !important;
  color: #FFFFFF !important;
  width: 140px;
  height: 50px;
  padding-top: 13px;
  margin-top: 6px;
  font-size: 17px !important;
}
@media(min-width : 900px){
  ul.nav.nav-tabs {
    margin-left: 27%;
}

}
.viewBox-count{
  background: #F3F3F3 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 68px;
  width: 160px;
  margin-bottom: 50px;
  margin-top: 5px;
}
.viewbox-number{
  padding-top: 10%;
  font-size: 30px;
}

.notActive{
  text-align: center;
  font: normal normal medium 17px/26px" Gotham Narrow" !important;
  letter-spacing: 0px !important;
  color: #2B6C3F !important;
  width: 124px;
  height: 50px;
  padding-top: 13px;
  margin-top: 6px;
  font-size: 17px !important;
}

.projectListCard {
  color: #fff;
  padding: 0px !important;
}

.projectListCardTitle {
  text-transform: capitalize;
  padding-left: 5%;
  padding-right: 5%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.mt-half {
  margin-top: 0.5%;
}

.topChangeProject {
  max-width: 100% !important;
  align-items: center;
  justify-content: flex-end;
}

.topChangeProject div .form-group {
  width: 240px;
  position: absolute;
  top: 1px;
  right: 10%;
}

#topbar .sp-project {
  width: 240px;
}

.topChangeProject .Select__option {
  color: black !important;
}

.topChangeProject .Select__control {
  background-color: #bb8b63;
}

.topChangeProject .Select__single-value {
  color: white !important;
}

.w-10 {
  width: 10%;
}
.w-12 {
  width: 12%;
}

.mw-35 {
  max-width: 35%;
}
.mw-43 {
  max-width: 43%;
}

.mr-9 {
  margin-right: 9%;
}

.mr-16 {
  margin-right: 16%;
}

.mr-24 {
  margin-right: 24%;
}

.projects_photoViewBox_body {
  max-width: 100%;
}

.carouselCard {
  top: 20%;
  left: 11%;
  position: absolute;
  height: 60%;
  width: 35%;
  padding: 3%;
}

.mt-7 {
  margin-top: 7%;
}

.ml-1p {
  margin-left: 1%;
}

.projectListDashboard .My_Plantings_PhotoView {
  border-radius: 5px;
  width: 100%;
  height: 165px;
  overflow: hidden;
  position: relative;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-13 {
  font-size: 13px;
}

.relative {
  position: relative
}

.dashboard-page {
  & .react-datepicker__input-container {
    & .required {
      height: 38px;
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #A0A0A0 !important;
      font: normal normal 600 12px/17px Open Sans;
      font-family: "OpenSans-SemiBold";
      letter-spacing: 0.12px;
      color: #010101;
    }
  }
}

.nested-select {
  width: 260px;
  & .css-1pahdxg-control {
    border: none !important;
    box-shadow: none !important
  }
  & .css-yt9ioa-option {
    font: normal normal normal 10px/12px Work Sans;
    font-family: "OpenSans-Regular";
    letter-spacing: 0.3px;
    color: #000000;
  }
  & .css-1n7v3ny-option {
    background-color: #bb8b63;
    font: normal normal normal 10px/12px Work Sans;
    font-family: "OpenSans-Regular";
    letter-spacing: 0.3px;
    color: #FFFFFF;
  }
  & .css-syji7d-Group {
    & .css-yt9ioa-option, & .css-1n7v3ny-option {
      padding-left: 24px;
    }
  }
}

.bold {
  font-weight: 600 !important;
}